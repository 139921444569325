<template>
  <vca-card v-if="current">
    <h2>{{ $t("network.crews.view.header", { 0: current.name }) }}</h2>
    <CrewView />
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import CrewView from "@/components/network/crews/CrewView";
export default {
  name: "NetworkCrewView",
  components: { CrewView },
  created() {
    this.$store.dispatch({ type: "crews/list" }).then(() => {
      this.current = this.findById(this.$route.params.id);
    });
  },
  computed: {
    current: {
      get() {
        return this.$store.state.crews.current;
      },
      set(value) {
        this.$store.commit("crews/current", value);
      },
    },
    ...mapGetters({
      findById: "crews/findById",
    }),
  },
};
</script>
