<template>
  <vca-column>
    <div>
      <h3>{{ $t("crews.name.label") }}</h3>
      <div>{{ current.name }}</div>
    </div>
    <div>
      <h3>{{ $t("crews.email.label") }}</h3>
      <div>{{ current.email }}</div>
    </div>
    <div>
      <h3>{{ $t("crews.abbreviation.label") }}</h3>
      <div>{{ current.abbreviation }}</div>
    </div>
    <div>
      <h3>{{ $t("crews.cities.label") }}</h3>
      <div class="tags-container">
        <div class="tag" v-for="res in current.cities" :key="res.city">
          <div class="tag-label">{{ res.city }} ({{ res.country }})</div>
        </div>
      </div>
    </div>
    <div>
      <vca-map
        ref="map"
        :hideFields="true"
        :disabled="true"
        v-model="cityList"
      />
    </div>
  </vca-column>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CrewView",
  watch: {
    api_lang: {
      handler(value) {
        if (this.$refs.map) {
          this.$refs.map.setLanguage(value);
        }
      },
    },
  },
  computed: {
    cityList: {
      get() {
        this.current.cities.forEach((el) => {
          (el.title = el.city), (el.subtitle = "");
        });
        return this.current.cities ? this.current.cities : [];
      },
      set() {},
    },
    ...mapGetters({
      api_lang: "api_lang",
      current: "crews/current",
    }),
  },
};
</script>
